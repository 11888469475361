<template>
    <div>
        <Cart
            :show="show"
            @showShoppingCart="showShoppingCart" />

        <div class="self_action_sheet">
            <Action-Sheet
                v-model="show"
                :round="false">
                <div class="content">
                    <div class="header">
                        <div>
                            <Checkbox
                                v-model.trim="allChecked"
                                @click="handleAllChecked">
                                <span class="all_check ml6">全选</span>
                                <template #icon="props">
                                    <img
                                        :src="props.checked
                                            ? require('@img/checkbox_active.png')
                                            : require('@img/checkbox_default.png')"
                                        class="icon_checkbox"
                                        alt="">
                                </template>
                            </Checkbox>
                        </div>
                        <div
                            @click="emptyCart"
                            class="btn_text">
                            全部清空
                        </div>
                    </div>
                    <div class="body">
                        <Checkbox-Group
                            ref="checkboxGroup"
                            v-model="result"
                            @change="handleChange">
                            <div
                                v-for="item in list"
                                :key="item.goodsId"
                                class="item">
                                <Checkbox
                                    :name="item.goodsId"
                                    refs="checkboxes">
                                    <template #icon="props">
                                        <img
                                            :src="props.checked
                                                ? require('@img/checkbox_active.png')
                                                : require('@img/checkbox_default.png')"
                                            class="icon_checkbox"
                                            alt="">
                                    </template>
                                </Checkbox>
                                <div class="content ml14">
                                    <Drug-Item
                                        :showSpes="false"
                                        :memberPrice="item.memberPrice"
                                        :data="item">
                                        <template v-slot:numOrCount>
                                            <Counter :attr="mapDataToAttr(item)" />
                                        </template>
                                    </Drug-Item>
                                </div>
                            </div>
                        </Checkbox-Group>
                    </div>
                </div>
            </Action-Sheet>
        </div>

        <Dialog
            v-model="showDialog"
            :confirm-button-color="'#F76D32'"
            @confirm="handleConfirm"
            @cancel="handleCancel"
            confirm-button-text="确认"
            cancel-button-text="取消"
            width="280px"
            class="dialog"
            show-cancel-button>
            <div class="tip">
                确认清空购物清单？
            </div>
        </Dialog>
    </div>
</template>
<script>
import {
    ActionSheet, CheckboxGroup, Checkbox, Dialog,
} from 'vant';
import DrugItem from '@/components/order/drug-item';
import Counter from '@/components/business/add-subtract';
import {
    mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';
import Cart from './cart';

export default {
    components: {
        ActionSheet,
        CheckboxGroup,
        Checkbox,
        Dialog: Dialog.Component,
        Counter,
        DrugItem,
        Cart,
    },
    props: {
        merchantCode: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            show: false,
            allChecked: false,
            result: [],
            showDialog: false, // 清空购物车的二次确认弹窗状态
        };
    },
    computed: {
        ...mapState({
            list: (store) => store.shoppingCart.list,
        }),
        ...mapGetters('shoppingCart', [
            'mapDataToAttr',
            'mapResult',
        ]),
    },
    watch: {
        list(n) {
            if (!n.length) {
                this.show = false;
            }
        },
    },
    created() {
        this.initCartComponent();
    },
    methods: {
        ...mapMutations('shoppingCart', [
            'updateCartCheckedState',
        ]),
        ...mapActions('shoppingCart', [
            'initCartAsync',
            'updateCart',
            'emptyCartAsync',
        ]),
        showShoppingCart(bool) {
            this.show = bool;
            if (bool) {
                this.result = this.list.filter((item) => item.isSelected === 1).map((item) => item.goodsId);
                this.allChecked = this.result.length === this.list.length;
            }
        },
        handleCancel() {
            this.showDialog = false;
        },
        handleConfirm() {
            this.emptyCartAsync({ merchantCode: this.merchantCode });
            this.show = false;
            this.result = [];
        },
        handleAllChecked() {
            if (this.allChecked) {
                this.$refs.checkboxGroup.toggleAll(true);
            } else {
                this.$refs.checkboxGroup.toggleAll();
            }
        },
        handleChange() {
            this.updateCartCheckedState({
                result: this.result,
                cb: (newR) => {
                    this.allChecked = newR.length === this.list.length;
                },
            });
            // this.allChecked = this.result.length >= this.list.length;
        },
        emptyCart() {
            this.showDialog = true;
        },
        async initCartComponent() {
            await this.initCartAsync({ merchantCode: this.merchantCode });
        },
    },
};
</script>
<style lang="scss" scoped>
.content {
    .header {
        display: flex;
        justify-content: space-between;
        padding: 24px 18px;

        .all_check {
            font-size: 14px;
            // font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333;
        }

        .btn_text {
            font-size: 14px;
            // font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #3679f0;
        }
    }

    .body {
        padding: 0 18px;
        max-height: 300px;
        overflow: scroll;

        .item {
            display: flex;

            .content {
                flex: 1;
            }
        }
    }
}

.self_action_sheet /deep/ .van-overlay {
    top: auto;
    bottom: constant(safe-area-inset-bottom);
    bottom: env(safe-area-inset-bottom);
    bottom: 50px;
    margin-bottom: 16px;
}

/deep/ .van-popup--bottom {
    bottom: constant(safe-area-inset-bottom);
    bottom: env(safe-area-inset-bottom);
    bottom: 50px;
    margin-bottom: 16px;
}

/deep/ .van-dialog {
    border-radius: 4px;
}

/deep/ .van-button__text {
    font-size: 18px;
}

.tip {
    padding: 24px 22px;
    font-size: 18px;
    // font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000;
    line-height: 25px;
    background: #fff;
    text-align: center;
}
</style>
