<template>
    <div>
        <div
            :class="{'fixed': fixed}"
            class="container pl12 pr12">
            <div class="shopping">
                <div class="left ml4">
                    <div
                        @click="showShoppingCart"
                        class="img">
                        <img
                            :src="list.length
                                ? require('@img/shopping/cart_active.png')
                                : require('@img/shopping/cart_none.png')"
                            class="icon_shopping mr8"
                            alt="">
                        <div
                            v-show="bePaidCount > 0"
                            class="badge">
                            <span v-if="bePaidCount <= 99">{{ bePaidCount }}</span>
                            <span v-else>99+</span>
                        </div>
                    </div>
                    <div class="text">
                        <span
                            v-if="list.length"
                            class="price ml16">
                            <span
                                v-if="isMember === '1' && hasMemberProduct"
                                class="mr12">¥{{ memberPrice }}</span>
                            <span :class="{'origin_price': isMember === '1' && hasMemberProduct}">¥{{ allPrice }}</span>
                        </span>
                        <span
                            v-else
                            class="ml8">请选择药品</span>
                    </div>
                </div>
                <div
                    v-show="list.length"
                    class="right mr4">
                    <Button
                        :class="{'active': bePaidCount > 0, 'disabled': bePaidCount === 0}"
                        @click="toPay"
                        type="default"
                        class="btn">
                        提交需求
                    </Button>
                </div>
            </div>
        </div>

        <Dialog
            v-model.trim="showDialog"
            :confirm-button-color="'#F76D32'"
            @confirm="handleConfirm"
            confirm-button-text="知道了"
            width="280px"
            class="dialog">
            <div class="tip">
                <!-- <p
                    v-for="(item, index) in tips"
                    :key="index">
                    {{ item }}
                </p> -->
                {{ tips }}
            </div>
        </Dialog>
    </div>
</template>
<script>
import { Button, Dialog } from 'vant';
import { mapState, mapMutations } from 'vuex';
import api from '@/api';

export default {
    components: {
        Button,
        Dialog: Dialog.Component,
    },
    props: {
        fixed: {
            type: Boolean,
            default: true,
        },
        show: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showDialog: false,
            tips: [],
            isMember: sessionStorage.getItem('isMember') || 0,
        };
    },
    computed: {
        ...mapState({
            list: (store) => store.shoppingCart.list,
        }),
        allPrice() {
            const sum = this.list
                .filter((item) => item.isSelected)
                .reduce((pre, next) => pre + (next.count * next.productPrice), 0);
            return sum.toFixed(2);
        },
        memberPrice() {
            const sum = this.list
                .filter((item) => item.isSelected)
                .reduce((pre, next) => pre + (next.count * (this.isMember === '1' && next.memberPrice ? next.memberPrice : next.productPrice)), 0);
            return sum.toFixed(2);
        },
        hasMemberProduct() {
            return this.list.filter((item) => item.memberPrice > 0 && item.isSelected).length;
        },
        bePaidCount() {
            return this.list
                .filter((item) => item.isSelected)
                .reduce((pre, next) => pre + next.count, 0);
        },
    },
    methods: {
        ...mapMutations('shoppingCart', [
            'updateCartAfterVerify',
        ]),
        showShoppingCart() {
            if (!this.list.length) return;
            this.$emit('showShoppingCart', !this.show);
        },
        handleConfirm() {
            this.showDialog = false;
        },
        toPay() {
            if (!this.bePaidCount) return;
            const param = {
                productList: this.list
                    .filter((item) => item.isSelected === 1)
                    .map((item) => ({
                        count: item.count,
                        goodsId: item.goodsId,
                    })),
            };
            // const param = {
            //     productList: [
            //         { count: 300, goodsId: '1296257679881330690' },
            //         { count: 10, goodsId: '1290615932702740481' },
            //     ],
            // };
            api.orderProductVerify(param).then((res) => {
                const { data } = res;

                // 下架校验
                const offTheShelf = data.some((item) => item.status !== 1);
                if (offTheShelf) {
                    const offTheShelfGoodsId = {};
                    data.forEach((item) => {
                        offTheShelfGoodsId[item.goodsId] = item.status;
                    });

                    const tips = [];
                    this.list.forEach((item) => {
                        if (offTheShelfGoodsId[item.goodsId] !== 1) {
                            tips.push(`${item.productName}(${item.usualName})`);
                        }
                    });
                    this.tips = `${tips.join(';')}已下架,请重新选择`;
                    this.showDialog = true;
                    return;
                }

                // 库存校验
                const notPassVerify = data.some((item) => !item.saleFlag);
                if (notPassVerify) {
                    const notPassGoodsId = {};
                    const currentStock = {};
                    data.forEach((item) => {
                        notPassGoodsId[item.goodsId] = item.saleFlag;
                        currentStock[item.goodsId] = item.availableNum;
                    });

                    const tips = [];
                    this.list.forEach((item) => {
                        if (!notPassGoodsId[item.goodsId]) {
                            tips.push(`${item.productName}(${item.usualName})剩余库存${currentStock[item.goodsId]}`);
                        }
                    });
                    this.tips = `${tips.join(';')},请重新选择`;
                    this.showDialog = true;
                    return;
                }

                this.updateCartAfterVerify(data);
                this.$router.push({
                    name: 'pay',
                });
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.fixed {
    // position: absolute; // 特殊需要，改为绝对定位
    position: fixed;
    bottom: constant(safe-area-inset-bottom);
    bottom: env(safe-area-inset-bottom);
    bottom: 0;
    left: 0;
    right: 0;
}
.container {
    background: #fff;
}
.shopping {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    background: #262726;
    border-radius: 25px;
    color: #fff;
    margin-bottom: 16px;
    .left {
        display: flex;
        margin-bottom: -1px;

        .img {
            position: relative;

            .icon_shopping {
                width: 42px;
                height: 42px;
            }

            .badge {
                position: absolute;
                top: 0;
                // right: 0;
                left: 31px;
                padding: 0 5px;
                height: 16px;
                line-height: 16px;
                color: #fff;
                background: #ff3b30;
                border-radius: 25px;
            }
        }

        .text {
            height: 42px;
            line-height: 42px;
            font-size: 14px;
            // font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ebebeb;

            .price {
                font-size: 18px;
                // font-family: Avenir-Medium, Avenir;
                font-weight: 500;
                color: #fff;
                line-height: 18px;
            }

            .origin_price {
                @include nonMember;
            }
        }
    }

    .btn {
        // width: 86px;
        height: 42px;
        line-height: 42px;
        font-size: 16px;
        // font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #fff;
        border-radius: 21px;
        border: none;

        &.active {
            box-shadow: 0 1px 11px 0 rgba(255, 92, 92, 0.5);
            background: linear-gradient(128deg, #ffd169 0%, #fd6500 100%);
        }

        &.disabled {
            background: linear-gradient(139deg, #4a4a4a 0%, #898989 100%);
        }
    }
}

/deep/ .van-dialog {
    border-radius: 4px;
}

/deep/ .van-button__text {
    font-size: 18px;
}

.tip {
    padding: 24px 22px;
    font-size: 16px;
    // font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000;
    line-height: 25px;
    background: #fff;
    text-align: center;
}
</style>
