<template>
  <div @click.stop class="add_subtract_wrapper">
    <div class="subtract_wrapper">
      <span
        v-show="!(zeroShowSubtract === false && num === 0)"
        :class="{
          subtract_active: isSubtractActive,
          subtract_disabled: !isSubtractActive,
        }"
        @click.stop="handleSubtract"
        class="subtract"
      />
    </div>
    <div class="num_wrapper">
      <span v-show="!(zeroShowSubtract === false && num === 0)" class="num">{{
        num
      }}</span>
      <!-- <input type="text" style="height: 20px; width: 50px;" v-model="num" v-show="!(zeroShowSubtract == false && attr.num == 0)"> -->
    </div>
    <div class="add_wrapper">
      <span
        :class="{ add_active: isAddActive, add_disabled: !isAddActive }"
        @click.stop="handleAdd"
        class="add"
      />
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { mapActions } from 'vuex';
import config from '@/config';

export default {
  name: 'AddSubtract',
  props: {
    attr: {
      type: Object,
      required: true,
    },
    zeroShowSubtract: {
      type: Boolean,
      required: false,
      // default: true,
    },
  },
  data () {
    return {
      num: 0,
      productCode: '',// 药品code
      timer: null, // setTimeout指针
      delay: 300, // 防抖时长
    };
  },
  computed: {
    isSubtractActive () {
      if (this.num > 0) {
        return true;
      } if (this.num === 0) {
        return false;
      }
      return false;
    },

    productCodeList () {
      return config.activityProductCode['NHS_DTP'].productCodeList
    },
    purchaseNum () {
      return config.activityProductCode['NHS_DTP'].purchaseNum

    },
    isAddActive () { //true 可点击   false 置灰
      // 没库存
      if (this.num >= this.attr.availableNum) {
        return false
      }
      // 有库存,不在限购名单里  
      if (!this.productCodeList.includes(this.productCode)) {
        return true
      }
      // 有库存,在限购名单里,超过了限购数量
      if (this.num >= this.purchaseNum) {
        return false;
      }
      // 有库存,在限购名单里,没超过限购数量
      return true
    },
  },
  watch: {
    attr (n) {
      this.num = n.num;
      this.productCode = n.productCode;
    },
  },
  created () {
    this.num = this.attr.num;
    this.productCode = this.attr.productCode;
  },
  methods: {
    ...mapActions('shoppingCart', [
      'updateCart',
    ]),
    handleAdd () {
      if (this.num >= this.attr.availableNum) {
        return Toast.fail('无多余库存了');
      }
      if (!this.isAddActive) {
        return Toast.fail(`该商品限购${this.purchaseNum}个！`);
      }
      this.num++;
      // SUBTRACT

      this.updateCart({
        ...this.attr,
        operateType: 'ADD',
        count: this.num,
      });

      // if (this.timer) {
      //     clearTimeout(this.timer);
      // }
      // this.timer = setTimeout(() => {
      //     this.updateCart({
      //         merchantCode: this.attr.merchantCode,
      //         operateType: 'ADD',
      //         count: this.num,
      //         goodsId: this.attr.goodsId,
      //     });
      // }, this.delay);

      // this.$emit('handleNumAdd', this.attr);
    },
    handleSubtract () {
      this.num--;

      this.updateCart({
        ...this.attr,
        operateType: 'SUBTRACT',
        count: this.num,
      });

      // if (this.timer) {
      //     clearTimeout(this.timer);
      // }
      // this.timer = setTimeout(() => {
      //     this.updateCart({
      //         merchantCode: this.attr.merchantCode,
      //         operateType: 'SUBTRACT',
      //         count: this.num,
      //         goodsId: this.attr.goodsId,
      //     });
      // }, this.delay);

      // this.$emit('handleNumSubtract', this.attr);
    },
  },
};
</script>

<style lang="scss" scoped>
.add_subtract_wrapper {
  width: 77px;
  height: 22px;
  font-size: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // margin-left: 2px;
  .subtract_wrapper {
    width: 22px;
    height: 22px;
  }
  .num_wrapper {
    width: 26px;
    height: 22px;
    font-size: 16px;
    // margin: 0 3px;
    text-align: center;
  }
  .add_wrapper {
    width: 22px;
    height: 22px;
  }
  .add,
  .subtract {
    display: inline-block;
    width: 22px;
    height: 22px;
    background-size: 22px 22px;
  }
  .add_active {
    background-image: url('~@img/add.png');
  }
  .add_disabled {
    background-image: url('~@img/add_disabled.png');
    // pointer-events: none;
  }
  .subtract_active {
    background-image: url('~@img/subtract.png');
  }
  .subtract_disabled {
    background-image: url('~@img/subtract_disabled.png');
    pointer-events: none;
  }
  .num {
    display: inline-block;
    // width: 46px;
    height: 22px;
    line-height: 22px;
    font-size: 14px;
    text-align: center;
  }
}
</style>
